import moment from 'moment-timezone'

export function elapsedTime (a, b, format = 'h:mm:ss') {
  a = moment(a)
  b = moment(b)

  const diff = a.diff(b)

  if (diff < 0) {
    return null
  }

  return moment.duration(diff).format(format, { trim: false })
}

export function debounce (wait) {
  let timeout = null

  const debounced = function (cb) {
    clearTimeout(timeout)

    timeout = setTimeout(cb, wait)
  }

  return debounced
}

export function copyTextToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function checkCanCompetitionShare(competition) {
  return competition.status !== 'awaiting' && competition.status !== 'sabotaged'
}
