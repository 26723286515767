import moment from 'moment-timezone'
import { mapState } from 'vuex'
import { elapsedTime } from '@/helpers'

export default {
  data() {
    return {
      save: false
    }
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user
    }),
    id() {
      return this.competition?.id || null
    },
    date() {
      const date = this.competition?.event_at

      if (!date) {
        return null
      }

      return moment(this.competition.event_at).format('DD MMM YYYY [at] hh:mm A')
    },
    dateOnly() {
      const date = this.competition?.event_at
      if (!date) return null

      return moment(this.competition.event_at).format('DD MMM YYYY')
    },
    timeOnly() {
      const date = this.competition?.event_at
      if (!date) return null

      return moment(this.competition.event_at).format('HH:mm')
    },
    submissionDate() {
      return moment(this.competition.event_at).subtract(5, 'd').format('DD MMM YYYY hh:mm A')
    },
    submissionDateShort() {
      return moment(this.competition.event_at).subtract(5, 'd').format('DD MMM YYYY')
    },
    submissionTime() {
      return moment(this.competition.event_at).subtract(5, 'd').format('HH:mm')
    },
    sport() {
      return this.competition?.kind_sport?.name?.toLowerCase()
    },
    timer() {

      if (this.isCurrentUserCompetition) {
        return null
      }

      const date = this.competition?.event_at

      if (!date) {
        return null
      }

      const now = moment()

      const start = moment(date).subtract(3, 'd')
      const end = moment(date).subtract(5, 'd')

      if (!now.isBetween(start, end)) {
        return null
      }

      return elapsedTime(end, now, 'h:mm')
    },
    gender_participants() {
      switch (true) {
        case this.competition.gender_participants === 'man':
        case this.competition.gender === 'man':
          return 'Men\'s';

        case this.competition.gender_participants === 'woman':
        case this.competition.gender === 'woman':
          return 'Women\'s';

        case this.competition.gender === 'mixed':
        case this.competition.gender_participants === 'mixed':
          return 'Mixed';

        default: return '';
      }
    },
    details() {
      const types = ['distance', 'style', 'type']

      const details = this.competition?.kind_sport?.types || []
      const info = types.reduce((info, type) => {
        const detail = details.find(detail => detail.name.toLowerCase() === type)

        info[type] = detail?.values[0]?.name || null

        return info
      }, {})
      const finalDetails = { ...info }
      // info.viewers = this.competition?.invites?.length || 0

      return finalDetails
    },
    venue() {
      const venue = this.competition?.kind_sport?.venue?.variations?.[0]

      if (!venue) {
        return null
      }

      return {
        name: venue.name,
        image: venue.image
      }
    },
    description() {
      return this.competition?.description || null
    },
    organizer() {
      const challenges = this.challenges

      if (!challenges?.length) {
        return null
      }

      return challenges[0].initiator
    },
    isCurrentUserCompetition() {
      if (this.organizer && this.$store?.state?.auth?.user?.id !== undefined) {
        return this.organizer.id === this.$store.state.auth.user.id
      } else {
        return false
      }
    },
    organized() {
      return this.organizer && this.organizer.id === this.user?.id
    },
    challenge() {
      return this.challenges.find(({ opponent, initiator }) => opponent.id === this.user?.id || initiator.id === this.user?.id)
    },
    challenges() {
      return this.competition?.challenges || []
    },
    isCompetitionHaveInvitedPersons() {
      return Object.keys(this.competition).includes('invites_with_hashes')
    },
    competitors() {
      const challenges = this.challenges
      const competitors = []

      if (!challenges?.length) {
        return competitors
      }

      if (!this.competition?.by_organizer) {
        competitors.push(this.organizer)
      }

      for (let challenge of challenges) {
        competitors.push(challenge.opponent)
      }

      if (this.isCompetitionHaveInvitedPersons) {
        this.competition?.invites_with_hashes.forEach(invite => {
          competitors.push(invite)
        })
      }
      // if (isInvitedInside) {
      //   const invitesArray = this.competition?.invites_with_hashes.map(invite => {
      //     return { initiator: this.user, opponent: invite }
      //   })
      //   this.competition.challenges = [...this.competition?.challenges, ...invitesArray]
      // }

      return competitors
    },
    inprogress() {
      return (this.organized && this.challenge?.has_initiator_video)
        || (!this.organized && this.challenge?.has_opponent_video)
    },
    accepted() {
      if (this.organized) {
        return true
      }

      const challenge = this.challenges.find(({ opponent }) => opponent.id === this.user?.id)

      return !!challenge?.accepted_at || false
    },
    rejected() {
      if (this.organized) {
        return false
      }

      const challenge = this.challenges.find(({ opponent }) => opponent.id === this.user?.id)

      if (!challenge) {
        return false
      }

      const date = this.competition.event_at

      const now = moment()

      const end = moment(date).subtract(4, 'd')

      if (now.isAfter(end)) {
        return true
      }

      return !!challenge.rejected_at || false
    },
    awaiting() {
      return this.challenges.every(({ accepted_at, rejected_at }) => !accepted_at && !rejected_at)
    },
    video() {
      return this.competition?.video?.url || null
    },
    videoPreview() {
      return this.competition?.video?.preview || null
    },
    participation() {
      return !!this.competitors.find(competitor => competitor && competitor.id === this.user?.id)
    },
    isMemberOfCompetition() {
      return this.competitors.some(competitor => competitor.id === this.user?.id);
    },
    saved() {
      return this.save
    },
    isAnyAcceptedOpponent() {
      return !!this.competition?.challenges?.some(challange => challange.status === 'Accepted')
    },
    showCompeteAction() {
      return !this.inprogress && this.participation
    },
    isCompeteButtonActive() {
      if (this.isCurrentUserUploadedVideo) return false;
      if (this.organized) {
        return this.isAnyAcceptedOpponent;
      } else {
        return this.challenge && !this.isCurrentUserRejectedCompetition;
      }
    },
    isCurrentUserUploadedVideo() {
      if (this.organized) {
        return this.competition?.challenges?.some(challenge => challenge?.has_initiator_video);
      } else {
        return this.competition?.challenges?.some(challenge => (
          challenge.opponent?.id === this.user?.id
          && challenge?.has_opponent_video
        ));
      }
    },
    isCurrentUserAcceptedInvite() {
      if (this.organized) {
        return true;
      } else {
        const challenge = this.this.competition?.challenges?.find(challenge => challenge?.opponent?.id === this.user?.id)
        return challenge?.status === 'Accepted';
      }
    },
    activeCompetitors() {
      const activeOpponentsIds = this.competition?.challenges.reduce((usersIds, challenge) => {
        return challenge?.status === "Accepted" ? [...usersIds, challenge.opponent.id] : usersIds;
      }, [])
      const competitionInitiatorId = this.competition?.challenges?.[0]?.initiator?.id;
      const activeCompetitorsIds = [...activeOpponentsIds, competitionInitiatorId];
      return this.competitors.filter(competitor => activeCompetitorsIds.includes(competitor.id));
    },
    isCompetitionStarted() {
      return !(
        this.competition?.status === "awaiting" ||
        this.competition?.status === "created" ||
        this.competition?.status === "performances-are-uploaded"
      )
    },
    competitorsList() {
      return this.isCompetitionStarted
        ? this.activeCompetitors
        : this.competitors
    },
  },
}
