<template>
  <view-container v-if="competition" name="details">
    <view-header>
      <custom-header class="view-header" :router-back="$goBack">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft" />
          {{ competition.name }}
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div class="competitors">
        <h4> {{ $t('pages.details.organizer') }} </h4>

        <router-link class="organizer" to="/">
          <a-avatar :src="avatar" :size="32">
            <a-icon slot="icon" type="user" />
          </a-avatar>

          <div class="info">
            <div class="name">
              <span> {{ competition.organizer.name }} </span>
            </div>
          </div>
        </router-link>

      </div>

      <div class="info">
        <a-divider></a-divider>

        <div class="details">
          <span> Competitors <span class="numbers ms-1">{{ competitorsCount }}</span> </span>
          <!-- <span class="value"> Viewers <span class="numbers ms-1">{{ competition.viewers }}</span> </span> -->
        </div>

        <a-divider></a-divider>

        <template>
          <h4>{{ $t('pages.details.info.date') }}</h4>

          <div class="date">
            <div class="event-date">
              <Icon class="icon" :size="16" type="calendar-16" />
              <span class="info__text"> {{ submissionDateShort }} </span>

              <Icon :size="16" type="timer-16" />
              <span class="info__text"> {{ submissionTime }} </span>
            </div>
          </div>

          <a-divider></a-divider>

          <h4>{{ $t('pages.details.info.eventdate') }} </h4>
          <div class="date">

            <div class="timer">
              <Icon :size="16" type="calendar-16" />
              <span class="info__text"> {{ dateOnly }} </span>

              <Icon :size="16" type="timer-16" />
              <span class="info__text"> {{ timeOnly }} </span>
            </div>
          </div>

          <a-divider></a-divider>
        </template>

        <h4> {{ $t('pages.details.info.preview') }} </h4>

        <template v-if="competition.venue">
          <div class="venue">

            <img :src="competition.venue.image">

            <span> {{ competition.venue.name }} </span>
          </div>
        </template>

        <a-divider></a-divider>

        <a-collapse>
          <a-collapse-panel v-for="heat, ind in sortedHeats" :key="ind" :header="`${heat.name} ${ind + 1}`">
            <template v-for="oponent, ind in heat.sortedChallenges">
              <div v-if="oponent.track_number" :key="ind" class="competitor-component drop-item">
                <div class="drop-item__info">
                  <div class="tracks__number">#{{ oponent.track_number }}</div>
                  <router-link class="avatar" to="/">
                    <a-avatar :src="oponent.opponent.photo">
                      <a-icon slot="icon" type="user" />
                    </a-avatar>
                  </router-link>

                  <div class="info">
                    <div class="name">
                      <span style="margin-bottom: 4px;"> {{ isCurrUser(oponent.opponent) ? 'You' : oponent.opponent.name
                      }} </span>
                    </div>

                    <div class="more">
                      <div class="country">
                        <img :src="oponent.opponent.country.icon" />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="right">
                  <div class="position" v-if="competition.status === 'start-list-revealed'">Line {{ oponent.track_number
                  }}</div>

                  <span class="status">
                    <Icon :size="10" type="check" style="margin-right: 4px;" />
                    Ready
                  </span>
                </div> -->
              </div>
            </template>
          </a-collapse-panel>
        </a-collapse>

        <template v-if="showPrice > -1 && (openAndRegOpen || closedAwaitingCreated)">
          <a-divider></a-divider>

          <h6 style="color: #fff;">Application Fee</h6>

          <div class='new-competition__balances details-balance'>
            <div class='new-competition__balances-item'>
              <p class='new-competition__balances-item-header'>Your balance</p>
              <svg-icon size="size16x16" name="icoCoin" />
              <span>{{ balance }}</span>
              <router-link :to='{ name: "coins-storage" }'>
                <Icon :size="14" type="add-circle" />
              </router-link>
            </div>
            <div class='new-competition__balances-item'>
              <p class='new-competition__balances-item-header'>Total on Venue</p>
              <svg-icon size="size16x16" name="icoCoin" />
              <span>{{ showPrice }}</span>
            </div>
          </div>
        </template>
      </div>
    </view-content>

    <view-footer>
      <template>
        <!-- <div
          v-if="competition.type === 'open' && competition.status === 'registration-open' && !competition.is_participant"
          class="upper-btn">
          <a-button @click="apply">
            Apply
          </a-button>
        </div> -->
        <!-- <div v-if="competition.status === 'start-list-revealed' && competition.is_participant" class="upper-btn">
          <router-link :custom="true" v-slot="{ navigate }"
            :to="{ name: 'record', params: { id: competition.me.competition_id } }">
            <a-button @click="navigate" :class="{ 'disabled': competition.me.has_video }">
              <Icon :size="17" type="sword" />
              <span v-if="!competition.me.has_video && competition">Compete now</span>
              <span v-else>In Progress</span>
            </a-button>
          </router-link>
        </div> -->



        <div class="actions" id="actionsButt">
          <div v-if="showApply" style="width: 49%;">
            <CommonButton @clicked="isBalanceEnough ? apply() : popupToStore = true" variant="flat" color="primary"
              caption="Apply" style="width: 100%;" :disabled="applyDisabled"></CommonButton>
          </div>
          <CommonButton v-if="showApply" @clicked="$router.go(-1)" variant="outline" color="primary" caption="Close"
            style="width: 48%;">
          </CommonButton>
          <template v-else-if="(openAndRegOpen || closedAwaitingCreated) && acceptReject">
            <a-button @click="isBalanceEnough ? accept() : popupToStore = true" style="width: 49%;"
              :disabled="acceptRejectDisabled">
              <Icon :size="20" type="check" />
              Accept
            </a-button>

            <a-button class="secondary" @click="reject" style="width: 49%;">
              <Icon :size="20" type="close" />
              Reject
            </a-button>
          </template>
          <template v-else-if="isAccepted">
            <!-- <CommonButton v-if="!isRejected"
              // после ассепт
              :disabled="!isCompeteButtonActive || (!isCurrentUserUploadedVideo && afterMarksStatus)" icon-size="20"
              //старт лист актив
              :icon-before="isCurrentUserUploadedVideo || (!isCurrentUserUploadedVideo && afterMarksStatus) ? 'time' : 'sword-solid'"
              variant="flat" :color="isCompeteButtonActive ? 'primary' : 'start'"
              //старт лист ревилд + видео или некст
              :caption="isCurrentUserUploadedVideo || (!isCurrentUserUploadedVideo && afterMarksStatus) ? 'Awaiting Results' : 'Compete'"
              @clicked="showCompetePopup">
            </CommonButton> -->
            <CommonButton v-if="isAccepted" icon-size="20" style="width: 49%; border: 0px solid black"
              :disabled="competeDisable || (isStartList && isUploadVideo) || (isUploadVideo && perfAreUpload) || (!isUploadVideo && perfAreUpload) || afterMarksStatus"
              :icon-before="(isStartList && isUploadVideo) || (isUploadVideo && perfAreUpload) || (!isUploadVideo && perfAreUpload) || afterMarksStatus ? 'time' : 'sword-solid'"
              variant="flat" :color="!competeDisable ? 'primary' : 'start'"
              :caption="(isStartList && isUploadVideo) || (isUploadVideo && perfAreUpload) || (!isUploadVideo && perfAreUpload) || afterMarksStatus ? 'Awaiting Results' : 'Compete'"
              @clicked="showCompetePopup">
            </CommonButton>
            <CommonButton @clicked="$router.go(-1)" variant="outline" color="primary" caption="Close" style="width: 48%;">
            </CommonButton>
          </template>
          <template
            v-else-if="notAcceptOrRejectClose || isRejected || notAcceptOrRejectOpen || withoutChallange || withoutChallangeOpen">
            <CommonButton @clicked="$router.go(-1)" variant="outline" color="primary" caption="Close"
              :style="showApply ? 'width: 49%;' : 'width: 100%'">
            </CommonButton>
          </template>
          <!-- <a-button @click="$router.push({ path: '/' })">
            {{ $t('pages.details.actions.close') }}
          </a-button> -->

          <!-- <a-button class="secondary" @click='isShowPopupShare = true'>
            <Icon :size="20" type="share" />

            {{ $t('pages.details.actions.share') }}
          </a-button> -->
        </div>
      </template>
    </view-footer>
    <Popup :open="popupToStore" @close="popupToStore = false">
      <h4>{{ $t('popup.toStore.title') }}</h4>

      <p>{{ $t('popup.toStore.description') }}</p>

      <template slot="actions">
        <a-button @click="goToStore">
          {{ $t('popup.toStore.btn') }}
        </a-button>
      </template>
    </Popup>
    <Popup v-if="competition" :open='isShowPopupShare' @close='isShowPopupShare = false'>
      <template v-if='canShare'>
        <div class='details__share'>
          <p v-if='linkWasCopied' class='details__copied'>{{ $t('competition.copyPopup.success') }}</p>
          <button @click='copySharedLink'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M10.8833 6.75845L12.0625 7.93761C12.6042 8.47929 13.0339 9.12238 13.3271 9.83015C13.6203 10.5379 13.7712 11.2965 13.7712 12.0626C13.7712 12.8287 13.6203 13.5873 13.3271 14.2951C13.0339 15.0028 12.6042 15.6459 12.0625 16.1876L11.7675 16.4818C10.6735 17.5758 9.18964 18.1904 7.64247 18.1904C6.09529 18.1904 4.61149 17.5758 3.51747 16.4818C2.42345 15.3878 1.80884 13.904 1.80884 12.3568C1.80884 10.8096 2.42345 9.3258 3.51747 8.23178L4.69664 9.41094C4.30694 9.79724 3.99739 10.2567 3.78574 10.7629C3.5741 11.2692 3.46453 11.8123 3.46333 12.361C3.46212 12.9097 3.56931 13.4532 3.77874 13.9604C3.98817 14.4676 4.29571 14.9284 4.6837 15.3164C5.0717 15.7044 5.53251 16.0119 6.03968 16.2213C6.54686 16.4308 7.0904 16.538 7.63911 16.5368C8.18781 16.5356 8.73088 16.426 9.23713 16.2143C9.74338 16.0027 10.2028 15.6931 10.5891 15.3034L10.8841 15.0084C11.6653 14.2271 12.1041 13.1675 12.1041 12.0626C12.1041 10.9578 11.6653 9.89814 10.8841 9.11678L9.70497 7.93761L10.8841 6.75928L10.8833 6.75845ZM16.4816 11.7676L15.3033 10.5893C15.693 10.203 16.0025 9.74352 16.2142 9.23727C16.4258 8.73103 16.5354 8.18796 16.5366 7.63925C16.5378 7.09054 16.4306 6.547 16.2212 6.03983C16.0118 5.53266 15.7042 5.07184 15.3162 4.68385C14.9282 4.29585 14.4674 3.98831 13.9603 3.77888C13.4531 3.56946 12.9095 3.46227 12.3608 3.46347C11.8121 3.46467 11.2691 3.57424 10.7628 3.78589C10.2566 3.99753 9.7971 4.30709 9.4108 4.69678L9.1158 4.99178C8.33467 5.77314 7.89586 6.83276 7.89586 7.93761C7.89586 9.04246 8.33467 10.1021 9.1158 10.8834L10.295 12.0626L9.1158 13.2409L7.93747 12.0626C7.39573 11.5209 6.966 10.8778 6.67281 10.1701C6.37962 9.4623 6.22871 8.70371 6.22871 7.93761C6.22871 7.17152 6.37962 6.41293 6.67281 5.70515C6.966 4.99738 7.39573 4.35429 7.93747 3.81261L8.23247 3.51845C9.32649 2.42443 10.8103 1.80981 12.3575 1.80981C13.9046 1.80981 15.3885 2.42443 16.4825 3.51845C17.5765 4.61246 18.1911 6.09627 18.1911 7.64345C18.1911 9.19062 17.5765 10.6744 16.4825 11.7684L16.4816 11.7676Z"
                fill="#7B8B97" />
            </svg>
            <span>Copy Link to this Event</span>
          </button>
        </div>
      </template>
      <template v-if='!canShare'>
        <h4> {{ $t('competition.popup.waiting.title') }} </h4>

        <p> {{ $t('competition.popup.waiting.description') }} </p>

        <template slot="actions">
          <a-button @click="isShowPopupShare = false">
            <Icon :size="17" type="check" />
            {{ $t('competition.popup.waiting.ok') }}
          </a-button>
        </template>
      </template>
    </Popup>
    <Popup :open="isReadyToCompeteNowPopupOpen" @close="hideCompetePopup">
      <template>
        <h4>{{ $t('competition.popup.compete.title') }}</h4>

        <template slot="actions">
          <router-link :custom="true" v-slot="{ navigate }" :to="{ name: 'record', params: { id: compId } }">
            <a-button @click="navigate">
              <Icon :size="20" type="check" />
              {{ $t('competition.popup.compete.ok') }}
            </a-button>
          </router-link>

          <a-button class="secondary" @click="hideCompetePopup">
            {{ $t('competition.popup.compete.cancel') }}
          </a-button>
        </template>
      </template>
    </Popup>
  </view-container>
</template>

<script>
import { mapGetters } from 'vuex'
import competition from '@/mixins/competition'
import Icon from '@/components/Icon'
import { getTournamentEvent } from '@/api/public/competition'
import moment from 'moment-timezone'
import { getEvent } from '@/api/public/competition'
import CommonButton from '@/components/UI/CommonButton'

import Popup from '@/components/Popup'
import { checkCanCompetitionShare, copyTextToClipboard } from '@/helpers'

export default {
  name: 'Details-tournament-event',
  mixins: [
    competition
  ],
  props: {
    eventId: {
      type: [String, Number],
    },
    detailEventID: {
      type: [String, Number],
    }
  },
  components: {
    Icon,
    Popup,
    CommonButton
  },
  data() {
    return {
      competition: null,
      isShowPopupShare: false,
      linkWasCopied: false,
      popup: false,
      isReadyToCompeteNowPopupOpen: false,
      popupToStore: false,
      applyDisabled: false,
      acceptRejectDisabled: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapGetters(['profileFilled']),
    ...mapGetters({
      'balance': 'balance'
    }),
    canShare() {
      return checkCanCompetitionShare(this.competition)
    },
    eventDate() {
      const date = this.competition?.event_at
      if (!date) {
        return null
      }
      return moment(date).format('DD MMM YYYY hh:mm A')
    },
    avatar() {
      return this.competitor?.photo || this.competition?.organizer?.photo || null
    },
    showPrice() {
      console.log('COMP TYPE', this.competition.type);
      console.log('COMP STATUS', this.competition.status);
      return this.competition?.price >= 0 ? this.competition.price : -1;

    },
    competitorsCount() {
      if (this.competition.competitors.length > 0 && typeof this.competition.competitors.length === 'number') {
        return this.competition.competitors.length
      } else {
        return this.competition.competitors
      }
    },
    openAndRegOpen() {
      return this.competition.type === 'open' && this.currentHeat.status === 'registration-open'
    },
    closedAwaitingCreated() {
      return this.competition.type === 'by invite' && (this.currentHeat.status === 'awaiting' || this.currentHeat.status === 'created')
    },
    isBalanceEnough() {
      return this.showPrice > -1 ? (this.balance - this.showPrice) >= 0 : false
    },
    isExpire() {
      return this.competition && this.competition.challenge_status === 'overdue'
    },
    showUserActions() {
      return this.participation && !this.accepted && !this.rejected
    },
    currChallenge() {
      for (let comp of this.competition.competitions) {
        if (comp.challenges) {
          let res = comp.challenges.find(el => el.opponent.id === this.$store?.state?.auth?.user?.id)
          if (res) {
            return res
          }
        }
      }
      return false
    },
    currentHeat() {
      if (this.competition.competitions.length === 0) {
        return 'new-open-event'
      }
      if (this.competition.competitions.length === 1) {
        return this.competition.competitions[0]
      }
      if (this.competition.type === 'open') {
        for (let heat of this.competition.competitions) {
          // return heat
          if (heat.challenges.length === 0) {
            return heat
          } else {
            let res = heat.challenges.find(el => el.opponent.id === this.$store?.state?.auth?.user?.id)
            if (res || heat.challenges.length < 8 && heat.status === 'registration-open') {
              return heat
            }
          }
        }
        return false
      } else {
        for (let heat of this.competition.competitions) {
          if (heat.challenges) {
            let res = heat.challenges.find(el => el.opponent.id === this.$store?.state?.auth?.user?.id)
            if (res) {
              return heat
            }
          }
        }
        return false
      }
    },
    isAccepted() {
      return (this.currChallenge && this.currChallenge.status === 'Accepted') || (this.competition.is_participant && !this.currChallenge)
    },
    isWaiting() {
      return this.currChallenge && this.currChallenge.status === 'Waiting'
    },
    isRejected() {
      return this.currChallenge && this.currChallenge.status === 'Rejected'
    },
    showPublicActions() {
      return this.isAccepted || this.isRejected
    },
    // competeButtonActive() {
    //   console.log('CH', this.currChallenge);
    //   return true
    //   // if(this.isAccepted && )
    // },
    isUploadVideo() {
      console.log('Is upload', this.competition.me.has_video);
      return this.competition.me.has_video
    },
    isStartList() {
      return this.currentHeat && this.currentHeat.status === 'start-list-revealed' && this.isAccepted
    },
    competeDisable() {
      return this.currentHeat && (this.currentHeat.status === 'registration-open' ||
        this.currentHeat.status === 'registration-closed' ||
        this.currentHeat.status === 'created' ||
        this.currentHeat.status === 'awaiting') && this.isAccepted
    },
    perfAreUpload() {
      return this.currentHeat && this.currentHeat.status === 'performances-are-uploaded'
    },
    afterMarksStatus() {
      return this.currentHeat && (this.currentHeat.status === 'performances-are-marked-up'
        || this.currentHeat.status === 'judging-is-done'
        || this.currentHeat.status === 'video-post-prod-is-done'
        || this.currentHeat.status === 'video-is-scheduled'
        || this.currentHeat.status === 'video-is-live')
    },
    afterClose() {
      return this.afterMarksStatus
        || this.currentHeat.status == 'registration-closed'
        || this.currentHeat.status === 'performances-are-uploaded'
        || this.currentHeat.status === 'start-list-revealed'
    },
    showApply() {
      return (this.openAndRegOpen && !this.currChallenge && !this.competition.is_participant) || this.currentHeat === 'new-open-event'
    },
    regIsClosed() {
      return this.currentHeat && this.currentHeat.status === 'registration-closed' || !this.isAccepted
    },
    compId() {
      console.log(this.competition.me.competition_id);
      return this.competition.me.competition_id
    },
    acceptReject() {
      return this.currChallenge && this.currChallenge.status === 'Waiting'
    },
    notAcceptOrRejectClose() {
      return this.acceptReject && this.afterClose && this.competition.type === 'by invite'
    },
    notAcceptOrRejectOpen() {
      return this.acceptReject && this.currentHeat.status === 'registration-closed' && this.competition.type === 'open'
    },
    withoutChallange() {
      return !this.currChallenge && this.competition.type === 'by invite'
    },
    withoutChallangeOpen() {
      return !this.currChallenge && this.competition.type === 'open' && !this.competition.is_participant
    },
    sortedHeats() {
      const competitionsSorted = this.competition.competitions
      for (let comp of competitionsSorted) {
        comp.sortedChallenges = comp.challenges.sort((a, b) => (a.track_number > b.track_number) ? 1 : -1)
      }
      return competitionsSorted
    }
  },
  methods: {
    showCompetePopup() {
      this.isReadyToCompeteNowPopupOpen = true;
    },
    hideCompetePopup() {
      this.isReadyToCompeteNowPopupOpen = false;
    },

    copySharedLink() {
      if (this.linkWasCopied) {
        return;
      }
      copyTextToClipboard(`${window.location.origin}/details-tournament-event/${this.competition.id}`)
      this.linkWasCopied = true
      setTimeout(() => {
        this.linkWasCopied = false
        this.isShowPopupShare = false
      }, 1500)

    },
    async initCompetition() {
      if (!this.eventId) {
        this.eventId = this.competition.id
      }
      try {
        const {
          data: competition
        } = await getTournamentEvent(this.eventId)
        this.competition = competition

      } catch (e) {
        console.log(e)
      }
    },
    async initEvent() {
      try {
        const {
          data: competition
        } = await getEvent(this.detailEventID)
        this.competition = competition

      } catch (e) {
        console.log(e)
      }
    },
    async apply() {
      this.applyDisabled = true
      if (this.profileFilled) {
        await this.$store.dispatch('setApply', {
          model: this.competition.model,
          event_id: this.competition.id
        })
        this.init()
      } else {
        this.togglePopup()
      }
      this.applyDisabled = false
    },
    togglePopup() {
      this.popup = !this.popup
    },
    signIn() {
      this.$router.push({
        name: 'registration',
        params: {
          hash: this.competitionId
        }
      })
    },
    async accept() {
      this.acceptRejectDisabled = true
      if (this.profileFilled) {
        await this.$store.dispatch('setAccept', this.currChallenge.id)
        this.init();
        this.$store.dispatch("eventHash/reset");
        this.isRulesPopupOpen = true;
      } else {
        this.togglePopup()
      }
      this.acceptRejectDisabled = false
    },
    async reject() {
      this.acceptRejectDisabled = true
      await this.$store.dispatch('setReject', this.currChallenge.id)
      this.init()
      this.$store.dispatch("eventHash/reset");
      this.acceptRejectDisabled = false
      // this.$router.go();
    },
    init() {
      const invalid = isNaN(Number(this.$route.params.id))
      if (invalid && !this.isLogin) {
        this.$router.push((`/invitation/${this.$route.params.id}`));
      } else {
        if (this.$route.name === 'details-event') {
          this.initEvent();
        } else {
          this.initCompetition();
        }
      }
    },
    goToStore() {
      this.popupToStore = false
      this.$router.push({ name: 'coins-storage' })
    },
    isCurrUser(user) {
      return user.id === this.$store?.state?.auth?.user?.id
    },
    sortedByTrack(heat) {
      let sorted = []
      sorted = heat.sort((a, b) => (a.track_number > b.track_number) ? 1 : -1)
      // console.log('HEATS', heats);
      // for (let heat of heats) {
      //   heat.sort((a, b) => (a.track_number > b.track_number) ? 1 : -1)
      //   sorted.push(heat)
      // }
      return sorted
    },
  }

}
</script>

<style scoped>
.actions {
  justify-content: space-between;
}

.organizer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin: 0 0 16px 0;
  color: #fff;
  border-radius: 8px;
}

.details {
  font-size: 12px;
  color: #7B8B97;
}

.details .numbers {
  color: #fff;
}

.date {
  gap: 10px;
}

.tournament-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.tournament-link__name {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
}

.tournament-link__icon {
  fill: #fff;
}

.upper-btn {
  padding: 16px 16px 0;
}

.tracks__number {
  margin-right: 10px;
}
</style>
